.tabelaNash {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(13, 1fr);
    grid-gap: 0;
    width: 400px;
    height: 400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 8px 5px 10px 1px rgb(48, 47, 47);
    margin: 10px;
    overflow: hidden;

}

.tabelaNash2 {
    display: flex;
    background-color: turquoise;
    overflow: hidden;
    flex-wrap: wrap;
}

.cartas {
    /* font-size: 1.2vw; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cartasNome {
    font-size: 80%;
    font-weight: 900;
    color: black;
}

.cartasValor {
    font-size: 80%;
}